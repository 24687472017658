@import "~react-image-gallery/styles/css/image-gallery.css";

@font-face {
  font-family: 'ZhiMangXing';
  src: url('./assets/fonts/ZhiMangXing.ttf') format('truetype');
}

@font-face {
  font-family: 'Quicksand';
  src: url('./assets/fonts/Quicksand.ttf') format('truetype');
}

.sms-code-character {
    font-size: 24px;
    color: #494949;
    border: 1px solid #d6d6d6;
    border-radius: 8px;
    background: #fff;
}

.sms-code-character--selected {
    outline: 0;
    box-shadow: 0 0 0 3px #C4C4DB;
}


.alwasy-show-scroll-bar  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 3px;
    height: 3px;
}
  
.alwasy-show-scroll-bar  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(72, 70, 70, 0.5);
    box-shadow: 0 0 0.4px rgba(255, 255, 255, .5);
}
